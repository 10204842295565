import donationRepository from "./donationRepository";
import donationInvitationRepository from "./donationInvitationRepository";

export const repositories = {
  donation: donationRepository,
  donationInvite: donationInvitationRepository,
};

export const RepositoryFactory = {
  get: name => repositories[name]
};
