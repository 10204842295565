import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.donateApiBaseUrl}invitation`;

export default {
  fetchAllDonationInvitesRequests() {
    return httpClient.get(URL)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },

  deleteDonateInvite(id) {
    const url = URL + `/${id}`;
    return httpClient.delete(url)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
};
