import {httpClient} from "../../shared/shared-services/httpClient";
import {environment} from "@/environment";

const URL = `${environment.donateApiBaseUrl}donation`;
export default {
  fetchAllDonationRequests() {
    return httpClient.get(URL)
      .then(res => res.data.data)
      .catch(error => console.log(error));
  },
  delete(id) {
    const url = environment.donateApiBaseUrl + `donation/${id}`;
    return httpClient.delete(url)
      .then(res => res.data)
      .catch(error => console.log(error));
  },
  /*convertLngLatToString(lon,lat){
      return httpClient.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?/types=poi&language=ar&access_token=pk.eyJ1Ijoid2FzZWVtLTQ0MyIsImEiOiJja2gyOWM5azkwY2p5MzFsczh2ZXg5MWU1In0.DaMw9JBJWlux5K7ZffTyYA`)
          .then(res => res.data.features[0].place_name)
          .catch(error => console.log(error))
  }*/
};
