<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      @emitDeleteMultipleRecords="deleteDonateInvite($event, null , true)"
    />

    <vs-popup
      title="تفاصيل التبرع"
      :active.sync="popupActive"
    >
      <form>
        <div class="vx-row">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>المعرّف : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ record.id }}</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <h4>المُرسل : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ record.sender_name }}</h4>
          </div>
        </div>

        <div class="vx-row mt-5">
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4> أرقام المدعويين : </h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <p
              v-for="(item, index) in record.receiver_mobiles"
              :key="item.id"
            >
              {{ record.receiver_mobiles.length == 1 ? '' : index + 1 + ' - ' }} {{ item }}
            </p>
          </div>
        </div>

        <div class="vx-row mt-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-button
              ycolor="success"
              type="filled"
              @click="popupActive=false"
            >
              حسناً
            </vs-button>
          </div>
        </div>
      </form>
    </vs-popup>
  </div>
</template>
<script>

import AgTable from '../../../shared/shared-components/ag-grid/AgTable.vue';
import AgTableBtnCell from '../../../shared/shared-components/ag-grid/AgTableBtnCell.vue';
import {RepositoryFactory} from "../../donate-repositories/donateRepositoryFactory";

const donationInviteRepo = RepositoryFactory.get('donationInvite');

export default {
  name: "DonationInvitesMain",
  components: {
    AgTable,
  },

  data() {
    return {
      isLoading: true,
      gridOptions: null,
      gridApi: null,
      rowData: [],
      popupActive: false,
      record: {}
    };
  },

  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: 'اسم المُرسل',
          field: 'sender_name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الرسالة',
          field: 'message',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'المُرسل إليه/م',
          field: 'receiver_mobiles',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteDonateInvite(id, rowIndex);
            },
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record);
            },
            actions: ['view', 'delete']
          },
        },
      ];
    },

    deleteDonateInvite(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      donationInviteRepo.deleteDonateInvite(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.fetchAllDonationInvitesRequests();
          else {
            this.rowData.splice(index, 1);
            this.rowData = [...this.rowData];
          }
        }
        this.isLoading = false;
      });
    },

    viewRecordDetails(record) {
      this.popupActive = true;
      this.record = record;
    },

    fetchAllDonationInvitesRequests() {
      donationInviteRepo.fetchAllDonationInvitesRequests().then((data) => {
        this.rowData = data;
        this.isLoading = false;
      });
    },
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllDonationInvitesRequests();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>
<style scoped>
</style>
